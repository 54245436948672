import React, { useState } from "react"

import { getSession } from "../../services/auth"
import Alert from "react-bootstrap/Alert"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Badge from "react-bootstrap/Badge"
import ListGroup from "react-bootstrap/ListGroup"


import Banner from "./banner"

import css from "./pricing.module.css"
import { Link } from "../../services/routes" // "gatsby"
import routes from "../../services/routes"

const plans = [
	{
		planName: "Free",
		perks: [
			"Unlimited Boards",
			"Limited Sharing",
			"100 MB Storage",
			" ",
			" ",
			" ",
		],
		monthlyPriceAnnually: 0,
		monthlyPriceMonthly: 0,
	},
	{
		planName: "Standard",
		perks: [
			"(Under development)",
			"Unlimited Boards",
			"Join Groups",
			"Standard Sharing",
			"500 MB Storage",
			" ",
		],
		monthlyPriceAnnually: 4,
		monthlyPriceMonthly: 5,
	},
	{
		planName: "Premium",
		perks: [
			"(Under development)",
			"Unlimited Boards",
			"Join Groups",
			"Manage Groups",
			"Unlimited Sharing",
			"5 GB Storage",
		],
		monthlyPriceAnnually: 8,
		monthlyPriceMonthly: 10,
	}
];

const Plan = ({planData: {planName, perks, monthlyPriceAnnually, monthlyPriceMonthly}, billingMethod}) => {
	const session = getSession();
	return (
	<div className="col-lg-4">
		<div className="price_item">
			<div className="price_head">
				<h4>{planName}</h4>
			</div>
			<div className="price_body">
				<ul className="list">
					{
						perks.map((perk, index) => (
							<li key={index}>
                                {perk}&nbsp;
							</li>
						))
					}
				</ul>
			</div>
			<div className="price_footer">
				<h3 style={{marginBottom:0}}>
					<span className="dlr">$</span>
					{
						billingMethod=="annually" ?
						<span>{monthlyPriceAnnually}{' '}</span> :
						<span>{monthlyPriceMonthly}{' '}</span>
					}
					<span className="month">
						Per
						<br />
						Month
					</span>
				</h3>
				<p style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}>
					billed {billingMethod}
				</p>
				{ (session.user === null) &&
					<Link className="main_btn"
						to="/signup?plan=premium"
						style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}
					>
						Get Started
					</Link>
				}
				{ (session.user !== null) &&
					<Link className="main_btn" 
						style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}
						to="/pricing"
					>
						Upgrade
					</Link>
				}
			</div>
		</div>
	</div>
	);
};

const PlanList = ({billingMethod}) => (
	<div className="price_inner row">
		{
			plans.map((planData, index) => (
				<Plan planData={planData} billingMethod={billingMethod} key={index}/>
			))
		}
	</div>
);

const BillingMethodSwitch = ({billingMethod, setBillingMethod}) => (
	<span style={{margin:"0 auto"}}>
		<h4 style={{display:"inline"}}>monthly </h4>
		<label className={css.switch} style={{verticalAlign:"middle", marginBottom:0}}>
			<input id="billing_input" type="checkbox" checked={billingMethod=="annually"}
				onChange={event => setBillingMethod(event.target.checked ? "annually" : "monthly")}
			/>
			<span className={`${css.slider} ${css.round}`}></span>
		</label>
		<h4 style={{display:"inline"}}> annually</h4>
	</span>
);

const PricingContent = () => {
	const [billingMethod, setBillingMethod] = useState("annually");
	return (
		<section className="price_area">
			<div style={{margin:"50px auto", padding:"15px", textAlign:"justify", maxWidth:"700px"}}>
				{/*<div className="main_title" style={{marginBottom: "20px"}}>
					<h2>Flexible Pricing</h2>
				</div>*/}
                <Alert variant='primary' >
					<p>
                    
                    In the time of COVID-19, we are working hard to better
                    support STEM education at the high-school and university
                    levels, whether in-person or online. In response to an
                    overwhelming number of inquiries, we are offering a new
                    plan specifically designed with schools in mind. If you are
                    currently on one of our paid plans, you should already have
                    been contacted by email and are welcome to reach out to us
                    at contact@scratchwork.io if you have any questions.
                        
                    </p>
                    <p>
                    
                    In response to the pandemic, we have temporarily made
                    almost all features available for free. If you are
                    concerned about the impact that a future change to the Free
                    Beta plan may have on your class, we encourage you to
                    consider the Paid Beta plan.

                    </p>
                </Alert>
                    <p>
                    If you or your department or school would like to join the
                    Paid Beta program, please fill out <a>this form</a>.
					</p>
				{/*<div className="row" style={{textAlign:"center", marginBottom:"20px"}}>
					<BillingMethodSwitch billingMethod={billingMethod} setBillingMethod={setBillingMethod} />
				</div>*/}
                <Row>
                  <Col>
                <h2>Free Beta</h2>
                <Alert variant='secondary'>
                Recommended for occasional users</Alert>
                <ListGroup>
                    <ListGroup.Item>Create and share boards</ListGroup.Item>
                    <ListGroup.Item>Up to 2 simultaneous editors per board</ListGroup.Item>
                    <ListGroup.Item>Writing text, freehand drawing, and adding shapes</ListGroup.Item>
                    <ListGroup.Item>Scan drawings from a camera</ListGroup.Item>
                    <ListGroup.Item>Annotate PDFs</ListGroup.Item>
                </ListGroup>
                </Col>
                <Col>
                <h2>Paid Beta <Badge variant='primary'>New</Badge></h2>
                <Alert variant='secondary'>
                Recommended for schools and educators at only $1 per student per month</Alert>
                <ListGroup>
                    <ListGroup.Item>All of the Free Beta plan features PLUS</ListGroup.Item>
                    <ListGroup.Item>LaTeX support</ListGroup.Item>
                    <ListGroup.Item>Copy boards</ListGroup.Item>
                    <ListGroup.Item>Up to 7 simultaneous editors per board</ListGroup.Item>
                    <ListGroup.Item>Print or export as PDF</ListGroup.Item>
                    <ListGroup.Item>Ad Free</ListGroup.Item>
                    <ListGroup.Item>Planned features
                        <ul className={css.pricing}>
                            <li>Teacher/Admin dashboard</li>
                            <li>Integration with LMS</li>
                            <li>Up to 20 simultaneous editors per board</li>
                            <li>LaTeX pen</li>
                        </ul>
                    </ListGroup.Item>
                </ListGroup>
                </Col>
                </Row>
				{/*<PlanList billingMethod={billingMethod}/>*/}
			</div>
		</section>
	);
}

export default () => (
	<>
		<Banner title="Pricing" link={routes.pricing} />
        {/*<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
			<h4> </h4>Contact us directly about pricing! Please reach out to us at{' '}
			<a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>
		</div>*/}
		<PricingContent />
	</>
);
